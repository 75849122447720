<template>
  <v-card tile>
    <v-card-title class="grey darken-4 py-3 px-3 pa-lg-4">
      <v-container fluid class="pa-0">
        <v-row>
          <v-col cols="12" lg="12" xl="12" sm="12" md="12" :class="[$vuetify.breakpoint.sm ? '' : 'text-center', 'px-0 py-2']" align-self="center">
            <v-layout align-center class="mt-xl-2 pa-1 pa-lg-0">
              <v-flex :class="[xsOnly ? payload.refreshBtn ? 'text-left' : 'text-center' : 'text-left', 'mt-0 mx-xl-4 mx-lg-2 mx-sm-1']" lg2 md2 xl2 sm2 xs2>
                <v-btn v-if="xsOnly" fab x-small elevation="1" outlined dark @click="today" class="ml-n3">
                  <v-icon dark size="18"> mdi-calendar-today </v-icon>
                </v-btn>
                <v-btn v-else @click="today" outlined dark> today </v-btn>
                <v-tooltip bottom v-if="payload.refreshBtn">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-if="xsOnly" fab x-small elevation="1" outlined dark class="ml-2" absolute
                    style="margin: 2px 0 0 0;" v-on="on" v-bind="attrs" @click="refreshCalendar">
                      <v-icon dark size="18"> mdi-calendar-refresh-outline </v-icon>
                    </v-btn>
                    <v-btn small outlined dark class="ml-sm-2 pt-1 mt-0" height="36"
                    v-on="on" v-bind="attrs" v-else absolute @click="refreshCalendar">
                      <v-icon> mdi-calendar-refresh-outline </v-icon>
                    </v-btn>
                  </template>
                  <span> refresh </span>
                </v-tooltip>
              </v-flex>
              <v-flex :class="[payload.refreshBtn ? 'mr-n1' : 'ml-n4', 'text-center']" lg8 md7 xl8 sm8 xs9>
                <v-btn class="mx-1 mr-lg-1 mr-xl-0" outlined fab color="white" x-small @click="previous" elevation="1">
                  <v-icon size="18"> mdi-chevron-left </v-icon>
                </v-btn>
                <span class="font-weight-medium white--text subtitle-1 mx-0 mx-lg-1 ml-xl-3 mr-xl-2 cursor-pointer user-select-none"
                @click="refreshCalendar">
                  {{ calendarTitle }}
                </span>
                <v-btn class="mx-1" outlined fab color="white" x-small @click="next" elevation="1">
                  <v-icon size="18"> mdi-chevron-right </v-icon>
                </v-btn>
              </v-flex>
              <v-flex lg2 md3 xl2 xs1 sm2 :class="[xsOnly ? 'pr-8' : '', 'mx-xl-4 mx-lg-2 mx-sm-1 mx-0']">
                <v-select dark :items="listOfViews" v-model="calendarView" outlined dense hide-details color="primary"
                label="View" v-if="!xsOnly"></v-select>
                <v-menu bottom origin="center center" transition="scale-transition" v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark v-bind="attrs" v-on="on" fab x-small outlined elevation="1" class="ml-1">
                      <v-icon dark> mdi-eye </v-icon>
                    </v-btn>
                  </template>
                  <v-list class="py-1">
                    <v-list-item v-for="(item, i) in listOfViews" :key="i" @click="calendarView = item.value">
                      <v-list-item-title> {{ item.text }} </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-flex>
            </v-layout>
            <!-- <v-layout :class="xsOnly ? 'pb-1' : 'mt-2 mt-sm-0 mt-xl-1'" :style="xsOnly ? 'width: 95vw; overflow-x: auto;' : ''">
              <v-flex class="mx-2" lg2 md2 sm2 xl2></v-flex>
              <v-flex v-if="!isCustomerPortal" class="d-flex align-center justify-center white--text body-1 pl-16 pl-sm-2 px-lg-0 pl-lg-4" lg7 md7 sm7 xl7>
                <div>
                  <v-badge color="white" content="2" offset-x="20" offset-y="21" overlap>
                    <template #badge>
                      <span class="black--text font-weight-bold"> {{ specificEventCounts.unavailable }} </span>
                    </template>
                    <v-chip class="ma-2 py-3" color="green darken-3" dark x-small>
                      <span> {{ $t('unavailableDates')}} </span>
                    </v-chip>
                  </v-badge>
                </div>
                <div>
                  <v-badge color="white" content="2" offset-x="20" offset-y="21" overlap>
                    <template #badge>
                      <span class="black--text font-weight-bold"> {{ specificEventCounts.restricted_dates }} </span>
                    </template>
                    <v-chip class="ma-2 py-3" color="grey darken-2" dark x-small>
                      <span> {{ $t('restricted_dates')}} </span>
                    </v-chip>
                  </v-badge>
                </div>
                <div>
                  <v-badge color="white" content="2" offset-x="20" offset-y="21" overlap>
                    <template #badge>
                      <span class="black--text font-weight-bold"> {{ specificEventCounts.holidays }} </span>
                    </template>
                    <v-chip class="ma-2 py-3" color="orange darken-2" dark x-small>
                      <span> {{ $t('holidays')}} </span>
                    </v-chip>
                  </v-badge>
                </div>
                <div class="mr-2">
                  <v-badge color="white" content="2" offset-x="20" offset-y="21" overlap>
                    <template #badge>
                      <span class="black--text font-weight-bold"> {{ specificEventCounts.booked }} </span>
                    </template>
                    <v-chip class="ma-2 py-3" color="blue darken-3" dark x-small>
                      <span> {{ $t('booked_dates')}} </span>
                    </v-chip>
                  </v-badge>
                </div>
              </v-flex>
              <v-flex lg3 md3 sm3 xl3></v-flex>
            </v-layout> -->
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-progress-linear indeterminate color="success" :active="payload.loading || false"></v-progress-linear>
    <v-card-text class="pa-1">
      <full-calendar id="calendar" :config="config" :events="events" ref="calendar" :key="calendarKey"></full-calendar>
    </v-card-text>
  </v-card>
</template>
<script>
import { FullCalendar } from 'vue-full-calendar'
export default {
  props: ['events', 'payload'],
  data () {
    return {
      calendarView: this.payload.calendarView || 'month',
      calendarTitle: '',
      config: {
        eventLimit: this.payload.eventLimit || 3,
        height: this.payload.height || 555,
        header: false,
        defaultView: this.payload.defaultView || 'month',
        selectable: this.payload.selectable || true,
        droppable: this.payload.droppable || false,
        displayEventTime: this.payload.displayEventTime || false,
        sync: this.payload.sync || true,
        dragScroll: this.payload.dragScroll || false,
        editable: this.payload.editable || false,
        weekends: this.payload.weekends || true,
        firstDay: this.payload.firstDay || 0,
        eventRender: (event, element, view) => {
          this.$emit('eventRender', { event, element, view })
        },
        select: (start, end, event, view) => {
          this.$emit('select', { start, end, event, view })
        },
        eventClick: (event) => {
          this.$emit('eventClick', event)
        }
      },
      calendarKey: 1
    }
  },
  components: {
    FullCalendar
  },
  computed: {
    listOfViews () {
      const views = [{
        name: 'month',
        text: 'Month',
        value: 'month'
      }, {
        name: 'week',
        text: 'Week',
        value: 'agendaWeek'
      }, {
        name: 'day',
        text: 'Day',
        value: 'agendaDay'
      }]
      const result = []
      views.forEach((view) => {
        if (this.payload.listOfViews.includes(view.name)) result.push(view)
      })
      return result
    }
  },
  watch: {
    calendarView (val) {
      this.$refs.calendar.fireMethod('changeView', val)
      // sessionStorage.setItem('calendarView', val)
      this.calendarInit()
      // if (this.isCustomerPortal) this.getCPBookings()
    }
  },
  mounted () {
    this.calendarInit()
  },
  methods: {
    next () {
      this.$refs.calendar.fireMethod('next')
      this.calendarInit()
      // if (this.isCustomerPortal) this.getCPBookings()
    },
    today () {
      this.$refs.calendar.fireMethod('today')
      this.calendarInit()
      // if (this.isCustomerPortal) this.getCPBookings()
    },
    previous () {
      this.$refs.calendar.fireMethod('prev')
      this.calendarInit()
      // if (this.isCustomerPortal) this.getCPBookings()
    },
    calendarInit () {
      // this.events = []
      const calendarObj = this.$refs.calendar && this.$refs.calendar.fireMethod('getView')
      this.$root.$emit('calendar', calendarObj)
      this.calendarTitle = calendarObj.title
    },
    refreshCalendar () {
      this.$emit('refresh')
    }
  }
}
</script>
<style>
  @import '~fullcalendar/dist/fullcalendar.css';
  .fc-toolbar.fc-header-toolbar {
    display: none !important;
  }
  .fc-widget-content {
    color: #212121 !important;
    font-weight: 500 !important;
  }
  th.fc-widget-header {
    color: #212121 !important;
    font-weight: bolder !important;
    padding: 7px 0 !important;
  }
  .fc-day-number {
    font-weight: bolder !important;
    padding: 7px 10px !important;
  }
  .fc-event {
    padding: 3px 5px;
  }
</style>
